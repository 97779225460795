// External dependencies
import { graphql, useStaticQuery } from 'gatsby';
import { Container } from 'atomize';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';

// Local dependencies
import CustomsWarehousePage from '../../../components/contracts/createContracts/customs-warehouse/CustomsWarehousePage';
import CreateContractDecalaration from '../../../components/contracts/createContracts/decalaration/ContractDecalarationPages';
import { resetCreateContract } from '../../../components/contracts/createContracts/redux/actions';
import { ContractType } from '../../../components/contracts/createContracts/redux/types';
import CreateContractSvhPage from '../../../components/contracts/createContracts/svh/ContractSvhPage';
import TypicalContractPage from '../../../components/contracts/createContracts/typical-contract/CreateContractComponentPage';
import Layout from '../../../components/layout';
import formatMessage from '../../../intl/formatMessage';
import { isBrowser } from '../../../components/common/helpers';

export default function CreateContract() {
  const titleCustomWareHouse = formatMessage('contract');
  const titleCreateSVHContract = formatMessage('createSVHContract');
  const titleModelContract = formatMessage('modelContract');
  const {
    site: {
      siteMetadata: { titleTemplate },
    },
  } = useStaticQuery(query);

  const dispatch = useDispatch();

  const contractType =
    isBrowser && (localStorage.getItem('contractType') as string);

  useEffect(() => {
    return () => {
      isBrowser && localStorage.removeItem('contractType');

      dispatch(resetCreateContract());
    };
  }, []);

  switch (contractType) {
    case ContractType.CUSTOMS_WAREHOUSE:
      return (
        <Layout>
          <Container>
            <Helmet>
              <title>{titleTemplate.replace('%s', titleCustomWareHouse)}</title>
            </Helmet>
            <CustomsWarehousePage />
          </Container>
        </Layout>
      );
    case ContractType.CUSTOMS_DECLARATION:
      return (
        <Layout>
          <Container>
            <Helmet>
              <title>{titleTemplate.replace('%s', titleCustomWareHouse)}</title>
            </Helmet>
            <CreateContractDecalaration />
          </Container>
        </Layout>
      );
    case ContractType.TEMPORARY_STORAGE:
      return (
        <Layout>
          <Container>
            <Helmet>
              <title>
                {titleTemplate.replace('%s', titleCreateSVHContract)}
              </title>
            </Helmet>
            <CreateContractSvhPage />
          </Container>
        </Layout>
      );
    case ContractType.TYPICAL_CONTRACT:
      return (
        <Layout>
          <Container>
            <Helmet>
              <title>{titleTemplate.replace('%s', titleModelContract)}</title>
            </Helmet>
            <TypicalContractPage />
          </Container>
        </Layout>
      );
    default:
      return null;
  }
}

const query = graphql`
  query {
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`;
