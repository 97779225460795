/* External dependencies */
import { Col, Div, Row, Text } from 'atomize';
import { navigate } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React from 'react';

/* Local dependencies */
import CreacteContractSvh from '../../../../components/contracts/createContracts/svh/ContractSvh';
import BackNav from '../../../../components/nav/BackNav';
import DangerPopup from '../../../../components/popup/DangerPopup';
import formatMessage from '../../../../intl/formatMessage';
import { useBackNavClick } from '../hooks';

export default function CreateContractSvhPage() {
  const [showExitConfirmation, setConfirmationState, onClickBackNav] =
    useBackNavClick('/contracts');

  return (
    <Div p={{ b: '8rem' }}>
      <Row>
        <Col>
          <BackNav
            label={formatMessage('createSVHContract')}
            onClick={onClickBackNav}
          />
          <CreacteContractSvh />
        </Col>
      </Row>
      {showExitConfirmation && (
        <DangerPopup
          cancelText={<FormattedMessage id="cancel" />}
          onClose={() => setConfirmationState(false)}
          onSubmit={() => navigate('/contracts')}
          submitText={<FormattedMessage id="exit" />}
          title={<FormattedMessage id="newContractExitTitle" />}
        >
          <Text>
            <FormattedMessage id="newContractExitDescription" />
          </Text>
        </DangerPopup>
      )}
    </Div>
  );
}
