/* External dependencies */
import { Col, Div, Row, Text } from 'atomize';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Local dependencies */
import formatMessage from '../../../../intl/formatMessage';
import DangerButton from '../../../buttons/DangerButton';
import PrimaryButton from '../../../buttons/PrimaryButton';
import DropdownPicker from '../../../custom-dropdown/DropdownPicker';
import FormField from '../../../form/FormField';
import { updateCreateContractFields } from '../redux/actions';
import { ContractField, eventTypes, PriceUnit, units } from '../redux/types';

export interface CreateContractFormProps {
  data?: ContractField[];
  notes: string;
  btnText?: string;
  btnIcon?: string;
  onClick?: () => void;
  loading?: boolean;
  isInvalid?: boolean;
  index?: number;
}

export default function CreateContractForm({
  data = [],
  index,
  btnText,
  btnIcon,
  onClick,
  loading = false,
  isInvalid = false,
}: CreateContractFormProps) {
  const dispatch = useDispatch();

  const inputPlaceholders = {
    nameService: formatMessage('writeDynamicContractName'),
    valueService: formatMessage('writeDynamicContractValue'),
  };

  function removeRowFields(idx) {
    const deleted = {
      index: index,
      item: { index: idx },
    };

    dispatch(updateCreateContractFields({ removeFields: deleted }));
  }

  function removeFieldsItem(idx, itemIndex) {
    const deleted = {
      index: index,
      item: { index: idx, item: { index: itemIndex } },
    };

    dispatch(updateCreateContractFields({ removeItemField: deleted }));
  }

  function addRowFields() {
    const field = {
      data: { name: '', values: [{ value: '', unit: PriceUnit.SOM }] },
      index: index,
    };

    dispatch(updateCreateContractFields({ addFields: field }));
  }

  function addFieldsItem(idx) {
    const data = {
      index: index,
      item: { index: idx, item: { index: '' } },
      data: {
        value: '',
        unit: PriceUnit.SOM,
      },
    };

    dispatch(updateCreateContractFields({ addFieldsItem: data }));
  }

  function updateFieldsName(e, idx) {
    const data = {
      index: index,
      item: { index: idx },
      name: e.target.value,
    };

    dispatch(updateCreateContractFields({ fieldName: data }));
  }

  function updateFieldsValue(e, idx, itemIndex) {
    const data = {
      index: index,
      item: { index: idx, item: { index: itemIndex } },
      value: e.target.value,
    };

    dispatch(updateCreateContractFields({ fieldValue: data }));
  }

  function updateFieldsUnit(e, idx, itemIndex) {
    const data = {
      index: index,
      item: { index: idx, item: { index: itemIndex } },
      value: e.target.value,
    };

    dispatch(updateCreateContractFields({ fieldUnit: data }));
  }

  function updateContractsTypes(e, idx) {
    const { name, value } = e.target;
    const data = {
      index,
      item: { index: idx },
      value,
    };

    dispatch(updateCreateContractFields({ [name]: data }));
  }

  return (
    <>
      {data &&
        data?.map((item, idx) => (
          <Row
            key={idx}
            p={{ y: '20px' }}
            style={{
              borderBottom: idx !== data.length - 1 ? '1px solid #C2C9D6' : '',
            }}
          >
            <Col size={{ xs: '12', lg: '5' }}>
              <Div d="flex">
                <Text textWeight="600" p={{ t: '9px' }}>
                  {idx + 1}.
                </Text>
                <Div w="100%">
                  <FormField
                    inputType="textarea"
                    error={item?.nameError}
                    placeholder={inputPlaceholders.nameService}
                    inputName={'fieldName'}
                    onChange={(e) => updateFieldsName(e, idx)}
                    inputValue={item.name}
                  />
                  <DropdownPicker
                    inputName="eventType"
                    items={eventTypes}
                    label=""
                    labelSize={{ xs: '12', sm: '5' }}
                    onChange={(e) => updateContractsTypes(e, idx)}
                    text="choseTypeContract"
                    value={item.eventType}
                  />
                  <Text
                    m={{ t: '-10px', b: '20px', l: '8px' }}
                    textSize="little"
                    textColor="red"
                  >
                    {item?.typeError && (
                      <FormattedMessage id={item?.typeError?.code} />
                    )}
                  </Text>
                </Div>
              </Div>
            </Col>
            <Col size={{ xs: '12', lg: '4' }}>
              <Div p={{ l: '11px' }}>
                {item?.values?.map((el, itemIndex) => (
                  <Div d="flex" key={itemIndex}>
                    <Div w="50%">
                      <FormField
                        inputType="number"
                        inputName="fields"
                        placeholder={inputPlaceholders.valueService}
                        onChange={(e) => updateFieldsValue(e, idx, itemIndex)}
                        inputValue={el.value}
                        error={el?.valueError}
                      />
                    </Div>
                    <Div w="100%">
                      <DropdownPicker
                        inputName="role"
                        items={units}
                        label=""
                        labelSize={{ xs: '12', sm: '5' }}
                        onChange={(e) => updateFieldsUnit(e, idx, itemIndex)}
                        text="chooseRole"
                        value={el.unit}
                      />
                    </Div>
                    <Div d={item?.values.length === 1 ? 'none' : 'flex'}>
                      <DangerButton
                        onClick={() => removeFieldsItem(idx, itemIndex)}
                      >
                        -
                      </DangerButton>
                    </Div>
                  </Div>
                ))}
              </Div>
            </Col>
            <Col size={{ xs: '12', lg: '1' }}>
              <Div
                d="flex"
                style={{ gap: '20px' }}
                w="100%"
                p={{ l: { xs: '18px', lg: '0' } }}
              >
                <Div d="flex" flexDir="column" w="100%" style={{ gap: '15px' }}>
                  <PrimaryButton size="md" onClick={() => addFieldsItem(idx)}>
                    +
                  </PrimaryButton>
                </Div>
                <Div maxW="200px">
                  {data.length > 1 && (
                    <DangerButton
                      size="md"
                      onClick={() => removeRowFields(idx)}
                      icon="Delete"
                    >
                      <FormattedMessage id="delete" />
                    </DangerButton>
                  )}
                </Div>
              </Div>
            </Col>
          </Row>
        ))}
      <Col size={12}>
        <Div p={{ x: { xs: '0', lg: '11px' } }} maxW="250px">
          <PrimaryButton size="md" onClick={addRowFields} icon="Add">
            <FormattedMessage id="add" />
          </PrimaryButton>
        </Div>
      </Col>
      <Row w="100%">
        <Col size={{ xs: '12', sm: '6', md: '5', lg: '4' }}></Col>
        {onClick && btnText && (
          <Col size={{ xs: '12', sm: '6', md: '5', lg: '12' }}>
            <Div
              m={{ t: '4rem' }}
              d="flex"
              justify="flex-end"
              style={{ gap: '10px' }}
            >
              <Div maxW="400px" w="100%">
                <PrimaryButton
                  icon={btnIcon}
                  size="lg"
                  disabled={isInvalid || loading}
                  onClick={onClick}
                  loading={loading}
                >
                  {<FormattedMessage id={btnText} />}
                </PrimaryButton>
              </Div>
            </Div>
          </Col>
        )}
      </Row>
    </>
  );
}
