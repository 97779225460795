/* External dependencies */
import { Col, Div, Row, Text } from 'atomize';
import { navigate } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Local dependencies */
import formatMessage from '../../../../intl/formatMessage';
import { RootState } from '../../../../redux/store';
import BackNav from '../../../nav/BackNav';
import DangerPopup from '../../../popup/DangerPopup';
import ErrorPopup from '../../../popup/ErrorPopup';
import PrimaryPopup from '../../../popup/PrimaryPopup';
import SuccessPopup from '../../../popup/SuccessPopup';
import {
  createContractRequest,
  openShowPopup,
  resetError,
  updateCreateContractFields,
} from '../redux/actions';
import { CreateContractState } from '../redux/reducer';
import { ContractType } from '../redux/types';
import CreateContractComponent from './CreateContractComponent';
import LoadingPopupContent from '../../../popup/LoadingPopup';
import { useBackNavClick } from '../hooks';

export default function TypicalContractPage() {
  const dispatch = useDispatch();

  const {
    contract,
    loading,
    isSuccess,
    isShowConfirmationPopup,
    error,
    isFormChanged,
  } = useSelector(
    (state: RootState): CreateContractState => state.creacteContract,
  );

  const [isExitConfirmation, setExitConfirmationState, onClickBackNav] =
    useBackNavClick('/contracts');

  const [isErrorValidate, setErrorValidate] = useState(false);

  useEffect(() => {
    const times = new Date().getTime();
    const initialState = {
      tariffs: [],
      startDate: times,
      endDate: times,
      type: ContractType.TYPICAL_CONTRACT,
    };

    dispatch(
      updateCreateContractFields({
        TYPICAL_CONTRACT: initialState,
        isFormChanged: false,
      }),
    );
  }, []);

  let isFailed;
  const faliedArrays: boolean[] = [];

  function nextSubMenu() {
    const isActive =
      new Date(contract?.startDate).getDay() ===
      new Date(contract?.endDate).getDay();

    if (isActive || !isFailed) {
      setErrorValidate(true);

      dispatch(updateCreateContractFields({ endDate: contract?.endDate }));

      contract?.tariffs.forEach((tariff, index) => {
        tariff.fields.forEach((field, idx) => {
          const data = {
            index: index,
            item: { index: idx },
            name: field.name || '',
            value: field.eventType || '',
          };

          dispatch(updateCreateContractFields({ eventType: data }));
          dispatch(updateCreateContractFields({ fieldName: data }));

          field?.values?.forEach((value, itemIndex) => {
            const dataForValue = {
              index,
              item: { index: idx, item: { index: itemIndex } },
              value: value.value || '',
            };

            dispatch(updateCreateContractFields({ fieldValue: dataForValue }));

            isFailed = !!field.name && !!field.eventType && !!value.value;
            faliedArrays.push(
              !!field.name && !!field.eventType && !!value.value,
            );
          });
        });
      });
    }

    if (!faliedArrays.includes(false) && !isActive) {
      setErrorValidate(false);
      dispatch(openShowPopup(true));
    }
  }

  function submitContract() {
    dispatch(createContractRequest(contract));
  }

  function navigateToListContracts() {
    navigate('/contracts');
  }

  return (
    <Div>
      <Div>
        <Row>
          <Col>
            <BackNav
              label={formatMessage('modelContract')}
              onClick={onClickBackNav}
            />
            <CreateContractComponent
              isDate
              onSubmit={nextSubMenu}
              validateError={isErrorValidate}
              setValidateError={() => setErrorValidate(false)}
            />
          </Col>
        </Row>
        {isExitConfirmation && (
          <DangerPopup
            cancelText={<FormattedMessage id="cancel" />}
            onClose={() => setExitConfirmationState(false)}
            onSubmit={() => navigate('/contracts')}
            submitText={<FormattedMessage id="exit" />}
            title={<FormattedMessage id="newContractExitTitle" />}
          >
            <Text>
              <FormattedMessage id="newContractExitDescription" />
            </Text>
          </DangerPopup>
        )}
      </Div>
      {loading && <LoadingPopupContent maxWidth="300px" />}
      {isShowConfirmationPopup && (
        <PrimaryPopup
          onClick={submitContract}
          onClose={() => dispatch(openShowPopup(false))}
          loading={loading}
          title={formatMessage('createModelContractQuestion')}
          btnText={formatMessage('create')}
          cancelBtnText={formatMessage('cancel')}
        ></PrimaryPopup>
      )}
      {isSuccess && (
        <SuccessPopup
          onSubmit={navigateToListContracts}
          title={formatMessage('modelContractAdded')}
          submitText={formatMessage('close')}
        >
          <Text>{contract?.consigneeProfile?.name}</Text>
        </SuccessPopup>
      )}
      {error && (
        <ErrorPopup
          title={formatMessage('errorOccurred')}
          submitText={formatMessage('close')}
          onSubmit={() => dispatch(resetError())}
        >
          {error.message}
        </ErrorPopup>
      )}
    </Div>
  );
}
