// External dependencies
import { Div, Icon, Text } from 'atomize';
import { navigate } from 'gatsby';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Local dependencies
import formatMessage from '../../../../intl/formatMessage';
import { RootState } from '../../../../redux/store';
import ErrorPopup from '../../../popup/ErrorPopup';
import LoadingPopupContent from '../../../popup/LoadingPopup';
import Popup from '../../../popup/Popup';
import PrimaryPopup from '../../../popup/PrimaryPopup';
import SuccessPopup from '../../../popup/SuccessPopup';
import ContractHeader from '../ContractHeader';
import { getValidateField, isActiveContractFields } from '../helper';
import {
  createContractRequest,
  openShowPopup,
  resetError,
  updateCreateContractFields,
} from '../redux/actions';
import { CreateContractState } from '../redux/reducer';
import { ContractType } from '../redux/types';
import CreateContractComponent from '../typical-contract/CreateContractComponent';

export default function CreacteContractSvh() {
  const dispatch = useDispatch();

  const [isErrorValidate, setErrorValidate] = useState(false);

  const { contract, loading, isSuccess, isShowConfirmationPopup, error } =
    useSelector(
      (state: RootState): CreateContractState => state.creacteContract,
    );

  useEffect(() => {
    dispatch(
      updateCreateContractFields({
        contractType: ContractType.TEMPORARY_STORAGE,
        isFormChanged: false,
      }),
    );
  }, []);

  const validateField = getValidateField(
    contract,
    ContractType.TEMPORARY_STORAGE,
  );
  const isActive = isActiveContractFields(contract);

  function nextSubMenu() {
    const dynamicFieldsErrors: boolean[] = [];

    if (
      dynamicFieldsErrors.length === 0 ||
      dynamicFieldsErrors.includes(false) ||
      !isActive
    ) {
      setErrorValidate(true);
      validateField.forEach((field) => {
        dispatch(updateCreateContractFields({ [field.name]: field.value }));
      });

      contract?.tariffs.forEach((tariff, index) => {
        tariff.fields.forEach((field, idx) => {
          const data = {
            index,
            item: { index: idx },
            name: field.name,
            value: field.eventType,
          };

          dispatch(updateCreateContractFields({ eventType: data }));
          dispatch(updateCreateContractFields({ fieldName: data }));

          dynamicFieldsErrors.push(!!field.name);
          dynamicFieldsErrors.push(!!field.eventType);

          field?.values?.forEach((value, itemIndex) => {
            const dataForValue = {
              index,
              item: { index: idx, item: { index: itemIndex } },
              value: value.value,
            };

            dispatch(updateCreateContractFields({ fieldValue: dataForValue }));

            dynamicFieldsErrors.push(!!value.value);
          });
        });
      });
    }

    if (isActive && !dynamicFieldsErrors.includes(false)) {
      setErrorValidate(false);
      dispatch(openShowPopup(true));
    }
  }

  function createContract() {
    dispatch(createContractRequest(contract));
  }

  function closedSavedPopup() {
    navigate('/contracts');
  }

  return (
    <Div>
      <ContractHeader />
      <CreateContractComponent
        onSubmit={nextSubMenu}
        validateError={isErrorValidate}
        setValidateError={() => setErrorValidate(false)}
      />

      {loading && <LoadingPopupContent maxWidth="300px" />}
      {isShowConfirmationPopup && (
        <PrimaryPopup
          onClick={createContract}
          onClose={() => dispatch(openShowPopup(false))}
          loading={loading}
          title={formatMessage('createConfirmationSVHContract')}
          btnText={formatMessage('create')}
          cancelBtnText={formatMessage('cancel')}
        >
          <Text>{contract?.consigneeProfile?.name}</Text>
        </PrimaryPopup>
      )}
      {isSuccess && (
        <SuccessPopup
          onSubmit={closedSavedPopup}
          title={formatMessage('createSavedSVHContract')}
          submitText={formatMessage('close')}
        >
          <Text>{contract?.consigneeProfile?.name}</Text>
        </SuccessPopup>
      )}
      {error && (
        <ErrorPopup
          title={formatMessage('errorOccurred')}
          submitText={formatMessage('close')}
          onSubmit={() => dispatch(resetError())}
        >
          {error.message}
        </ErrorPopup>
      )}
    </Div>
  );
}
