/* External dependencies */
import { Col, Div, Row, Text } from 'atomize';
import { navigate } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useDispatch, useSelector } from 'react-redux';

/* Local dependencies */
import CalendarIcon from '../../../../assets/svg/calendar';
import formatMessage from '../../../../intl/formatMessage';
import { RootState } from '../../../../redux/store';
import PrimaryButton from '../../../buttons/PrimaryButton';
import { dateNowFormatMessage } from '../../../common/DateTime';
import useOutsideClick from '../../../common/useOutsideClick';
import ContractHeader from '../../../contracts/createContracts/ContractHeader';
import FormField from '../../../form/FormField';
import { defaultInputStyle } from '../../../form/FormStyles';
import ErrorNotification from '../../../notifications/ErrorNotification';
import ErrorPopup from '../../../popup/ErrorPopup';
import LoadingPopupContent from '../../../popup/LoadingPopup';
import Popup from '../../../popup/Popup';
import PrimaryPopup from '../../../popup/PrimaryPopup';
import SuccessPopup from '../../../popup/SuccessPopup';
import {
  customWareHousestate,
  getValidateField,
  isActiveContractFields,
} from '../helper';
import {
  createContractRequest,
  openShowPopup,
  resetError,
  updateCreateContractFields,
} from '../redux/actions';
import { CreateContractState } from '../redux/reducer';
import {
  ConsigneeType,
  ContractType,
  EventType,
  PriceUnit,
} from '../redux/types';

export default function CreateContractTSCustomsWarehouse() {
  const dispatch = useDispatch();

  const { contract, loading, isSuccess, isShowConfirmationPopup, error } =
    useSelector(
      (state: RootState): CreateContractState => state.creacteContract,
    );

  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const refStartContractDate = useRef(null);
  const [isErrorValidate, setErrorValidate] = useState(false);

  useEffect(() => {
    dispatch(
      updateCreateContractFields({
        CUSTOMS_WAREHOUSE: customWareHousestate,
        isFormChanged: false,
      }),
    );

    dispatch(
      updateCreateContractFields({
        contractType: ContractType.CUSTOMS_WAREHOUSE,
        isFormChanged: false,
      }),
    );
  }, []);

  const inputLabel = {
    organizationLabel: formatMessage('organization'),
    organizationPlaceholder: formatMessage('addOrganization'),
    supervisorLabel: formatMessage('supervisor'),
    supervisorPlaceholder: formatMessage('addSupervisor'),
    positionLabel: formatMessage('position'),
    positionPlaceholder: formatMessage('addPosition'),
    adressCheckLabel: formatMessage('adressCheck'),
    adressCheckPlaceholder: formatMessage('addAdressCheck'),
    commencementionContract: formatMessage('commencementionContract'),
    endContract: formatMessage('endСontract'),
    individual: formatMessage('individual'),
    addIndividual: formatMessage('addIndividual'),
    confidant: formatMessage('confidant'),
    addConfidant: formatMessage('addConfidant'),
    powerAttorney: formatMessage('powerAttorney'),
    addPowerAttorney: formatMessage('addPowerAttorney'),
    cargoLabel: formatMessage('cargo'),
    addCargoDescriptionPlaceholder: formatMessage('addCargoDescription'),
    startContractDateLabel: formatMessage('startContractDate'),
    tarif: formatMessage('tarif'),
    tarifFieldDescription: formatMessage('contractCarParkingWithCargoFare'),
  };

  const validateField = getValidateField(
    contract,
    ContractType.CUSTOMS_WAREHOUSE,
  );
  const isActive = isActiveContractFields(contract);
  let isFailed;

  function nextSubMenu() {
    const dynamicFields = contract?.tariffs[0]?.fields.map((field) => {
      return {
        name: field.name,
        eventType: field.eventType || '',
        values: field.values,
      };
    });

    if (!isActive || !isFailed) {
      setErrorValidate(true);
      validateField.forEach((field) => {
        dispatch(updateCreateContractFields({ [field.name]: field.value }));
      });

      dynamicFields?.forEach((el, index) => {
        const data = {
          index: 0,
          item: { index },
          name: el.name,
          value: el.eventType,
        };

        dispatch(updateCreateContractFields({ eventType: data }));
        dispatch(updateCreateContractFields({ fieldName: data }));

        el.values?.forEach((value, idx) => {
          const dataForValue = {
            index: 0,
            item: { index: index, item: { index: idx } },
            value: value.value,
          };

          dispatch(updateCreateContractFields({ fieldValue: dataForValue }));

          isFailed = !!el.name && !!el.eventType && !!value.value;
        });
      });
    }

    if (isActive && isFailed) {
      setErrorValidate(false);
    }
  }

  function openConfirmationPopup() {
    nextSubMenu();

    if (isActive && isFailed) {
      setErrorValidate(false);
      dispatch(openShowPopup(true));
    }
  }

  function onSubmit() {
    dispatch(createContractRequest(contract));
  }

  useOutsideClick(refStartContractDate, () => {
    setIsStartDateOpen(false);
  });

  function udpateStartDate(e) {
    const getTimestems = e.getTime();

    dispatch(updateCreateContractFields({ startDate: getTimestems }));
    setIsStartDateOpen(false);
  }

  function updateFieldName(e, index, idx) {
    const data = {
      index: index,
      item: { index: idx },
      name: e.target.value,
    };

    dispatch(updateCreateContractFields({ fieldName: data }));
  }

  function updateFieldValue(e, index, idx, itemIndex) {
    const data = {
      index: index,
      item: { index: idx, item: { index: itemIndex } },
      value: e.target.value,
    };

    dispatch(updateCreateContractFields({ fieldValue: data }));
  }

  return (
    <Div>
      <ContractHeader isData={false} />
      <Row
        maxW={{ md: '1050px' }}
        align="flex-start"
        flexWrap={{ xs: 'wrap-reverse', lg: 'none' }}
      >
        <Col size={{ xs: '12', lg: '10' }}>
          {contract?.tariffs?.map((tariff, index) =>
            tariff.fields.map((field, idx) => (
              <FormField
                key={idx}
                label={inputLabel.cargoLabel}
                inputName="itemValue"
                inputType="textarea"
                inputValue={field.name}
                error={field.nameError}
                onChange={(e) => updateFieldName(e, index, idx)}
                tabIndex="3"
                placeholder={inputLabel.addCargoDescriptionPlaceholder}
                inputStyle={{
                  ...defaultInputStyle,
                  fontFamily: 'Open Sans',
                }}
              />
            )),
          )}
          <Row>
            <Col size={{ xs: '12', sm: '6' }}>
              <FormField
                label={inputLabel.startContractDateLabel}
                inputName="startaDate"
                inputType="text"
                whiteSpace="nowrap"
                inputValue={dateNowFormatMessage(contract?.startDate)}
                onClick={() => setIsStartDateOpen((prev) => !prev)}
                onChange={() => {}}
                inputStyle={{
                  cursor: 'pointer',
                  ...defaultInputStyle,
                }}
                suffixIcon={
                  <CalendarIcon
                    height={20}
                    width={20}
                    style={{ position: 'absolute', top: '10px', right: '10px' }}
                  />
                }
                reference={refStartContractDate}
              />
              {isStartDateOpen && (
                <>
                  <Col size={{ xs: '12', sm: '4', md: '3' }}></Col>
                  <Col pos="relative">
                    <Calendar
                      value={new Date(contract?.startDate)}
                      className="calendar"
                      locale="ru-RU"
                      next2Label={null}
                      prev2Label={null}
                      onChange={udpateStartDate}
                      onClick={() => setIsStartDateOpen(false)}
                    />
                  </Col>
                </>
              )}
            </Col>
            <Col size={{ xs: '12', sm: '6' }}>
              {contract?.tariffs?.map((tariff, index) =>
                tariff.fields.map((field, idx) =>
                  field.values.map(({ value, valueError }, itemIndex) => (
                    <FormField
                      key={itemIndex}
                      label={inputLabel.tarif}
                      inputName="itemValue"
                      inputType="text"
                      error={valueError}
                      inputValue={value}
                      onChange={(e) =>
                        updateFieldValue(e, index, idx, itemIndex)
                      }
                      fieldDescription={inputLabel.tarifFieldDescription}
                    />
                  )),
                ),
              )}
            </Col>
          </Row>
          <Row>
            <Col size={{ xs: '12', sm: '6', md: '12' }}>
              <Row m={{ t: '1rem' }}>
                <PrimaryButton
                  icon="Checked"
                  size="lg"
                  onClick={() => openConfirmationPopup()}
                  loading={loading}
                >
                  <FormattedMessage id={'createContractBtn'} />
                </PrimaryButton>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      {loading && <LoadingPopupContent maxWidth="300px" />}
      {isShowConfirmationPopup && !error && (
        <PrimaryPopup
          title={<FormattedMessage id="createTSContractQuestion" />}
          cancelBtnText={<FormattedMessage id="cancel" />}
          onClose={() => dispatch(openShowPopup(false))}
          btnText={<FormattedMessage id="create" />}
          loading={loading}
          onClick={onSubmit}
        >
          <Div>
            <Text>{contract?.consigneeProfile?.name}</Text>
          </Div>
        </PrimaryPopup>
      )}
      {isSuccess && (
        <SuccessPopup
          onSubmit={() => navigate('/contracts')}
          submitText={<FormattedMessage id="close" />}
          title={<FormattedMessage id="tsContractAdded" />}
        >
          <Div>
            <Text>{contract?.consigneeProfile?.name}</Text>
          </Div>
        </SuccessPopup>
      )}
      {error && (
        <ErrorPopup
          onSubmit={() => dispatch(resetError())}
          submitText={<FormattedMessage id="close" />}
          title={<FormattedMessage id="errorOccurred" />}
        >
          {error.message}
        </ErrorPopup>
      )}
      <ErrorNotification
        isOpen={isErrorValidate}
        onClose={() => setErrorValidate(false)}
        title={<FormattedMessage id="fillRequiredFields" />}
      />
    </Div>
  );
}
