// External dependencies
import { navigate } from 'gatsby';
import { useState } from 'react';
import { useSelector } from 'react-redux';

// Local dependencies
import { RootState } from '../../../redux/store';
import { CreateContractState } from './redux/reducer';

export function useBackNavClick(
  link: string,
): [boolean, (show: boolean) => void, () => void] {
  const { isFormChanged } = useSelector(
    (state: RootState): CreateContractState => state.creacteContract,
  );
  const [showExitConfirmation, setConfirmationState] = useState(false);

  function onClickBackNav() {
    isFormChanged ? setConfirmationState(true) : navigate(link);
  }

  return [showExitConfirmation, setConfirmationState, onClickBackNav];
}
