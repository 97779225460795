/* External dependencies */
import { Col, Div, Icon, Row, Text } from 'atomize';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useDispatch, useSelector } from 'react-redux';

/* Local dependencies */
import CalendarIcon from '../../../../assets/svg/calendar';
import formatMessage from '../../../../intl/formatMessage';
import { RootState } from '../../../../redux/store';
import { dateNowFormatMessage } from '../../../common/DateTime';
import FormField from '../../../form/FormField';
import { defaultInputStyle, rowStyle } from '../../../form/FormStyles';
import ErrorNotification from '../../../notifications/ErrorNotification';
import DangerPopup from '../../../popup/DangerPopup';
import SubMenu from '../../../topMenu/SubMenu';
import { getContract } from '../../getContract/redux/actions';
import { GetContractState } from '../../getContract/redux/reducer';
import { removeTariffId } from '../helper';
import { updateCreateContractFields } from '../redux/actions';
import { CreateContractState } from '../redux/reducer';
import { createContractNotes } from './contract-data';
import CreateContractForm from './CreateContractForm';

interface CreateContractComponentProps {
  isDate?: boolean;
  onSubmit?: () => void;
  setValidateError?: () => void | boolean;
  validateError?: boolean;
}

export default function CreateContractComponent({
  validateError = false,
  isDate = false,
  onSubmit,
  setValidateError,
}: CreateContractComponentProps) {
  const dispatch = useDispatch();

  const { contract: currentTypicalContract, loading } = useSelector(
    (state: RootState): GetContractState => state.getContract,
  );
  const { contract, endDateError, startDateError } = useSelector(
    (state: RootState): CreateContractState => state.creacteContract,
  );

  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);
  const [submenuItem, setSubmenuItem] = useState(0);
  const [startingTariff, setStartingTariff] = useState({
    popup: false,
    name: '',
    error: false,
  });

  useEffect(() => {
    dispatch(getContract('TYPICAL_CONTRACT'));
  }, []);

  useEffect(() => {
    if (currentTypicalContract?.tariffs?.length !== 0) {
      const tariffsWithoutId = removeTariffId(currentTypicalContract?.tariffs); // TODO: Rasul, here...

      dispatch(
        updateCreateContractFields({
          updateTariff: currentTypicalContract?.tariffs,
          isFormChanged: false,
        }),
      );
    } else {
      setStartingTariff((previousValue) => {
        return { ...previousValue, popup: true };
      });
    }
  }, [currentTypicalContract?.tariffs]);

  function onInputStartDateChange(e) {
    const times = new Date(e).getTime();

    dispatch(updateCreateContractFields({ startDate: times }));
    dispatch(updateCreateContractFields({ endDate: contract?.endDate }));
    setIsStartDateOpen(false);
  }

  function onInputEndDateChange(e) {
    const times = new Date(e).getTime();

    dispatch(updateCreateContractFields({ endDate: times }));
    dispatch(updateCreateContractFields({ startDate: contract?.startDate }));
    setIsEndDateOpen(false);
  }

  function onSubmenuItemClick(index) {
    setSubmenuItem(index);
  }

  function addNewTariffs() {
    if (startingTariff.name) {
      const newTariff = {
        name: startingTariff.name,
        fields: [
          {
            name: '',
            values: [
              {
                value: '',
                unit: 'SOM',
              },
            ],
          },
        ],
      };

      dispatch(
        updateCreateContractFields({
          addTariff: newTariff,
        }),
      );

      setStartingTariff({
        popup: false,
        name: '',
        error: false,
      });

      setSubmenuItem((prev) => (contract?.tariffs.length ? prev + 1 : prev));
    } else {
      setStartingTariff({
        ...startingTariff,
        error: { code: 'nameOfTariffRequiredException' },
      });
    }
  }

  function updateNameNewTariff() {
    if (startingTariff.name) {
      const newTariffName = {
        value: startingTariff.name,
        index: submenuItem,
      };

      dispatch(
        updateCreateContractFields({
          tariffName: newTariffName,
        }),
      );

      setStartingTariff({
        popup: false,
        name: '',
        error: false,
      });
    } else {
      setStartingTariff({
        ...startingTariff,
        error: { code: 'nameOfTariffRequiredException' },
      });
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (startingTariff?.update) {
      updateNameNewTariff();
    } else {
      addNewTariffs();
    }
  }

  function changeTariffName(e) {
    setStartingTariff({
      ...startingTariff,
      name: !startingTariff.name.length
        ? e.target.value.trim()
        : e.target.value,
      error: e.target.value.trim() ? null : startingTariff.error,
    });
  }

  function onCloseAddTariffPopup() {
    setStartingTariff({
      popup: false,
      name: '',
      error: false,
    });
  }

  function onSubmitAddTariffPopup() {
    if (startingTariff?.update) {
      updateNameNewTariff();
    } else {
      addNewTariffs();
    }
  }

  function deleteTariff() {
    dispatch(updateCreateContractFields({ removeTariff: submenuItem }));

    if (submenuItem > 0) {
      setSubmenuItem(submenuItem - 1);
    }
  }

  return (
    <Div>
      {isDate && (
        <Row
          {...rowStyle}
          d={{ xs: 'block', sm: 'flex' }}
          justify="space-between"
          maxW={{ md: '950px' }}
          w="100%"
        >
          <Col size={{ xs: '12', md: '5', lg: '5' }}>
            <Row p={{ r: { xs: '0.5rem', lg: '2rem' } }}>
              <FormField
                colSize="12"
                disabled
                label={<FormattedMessage id="startContractDate" />}
                inputName="calendar"
                inputType="text"
                inputValue={dateNowFormatMessage(contract?.startDate)}
                onClick={() => setIsStartDateOpen((prev) => !prev)}
                required={true}
                tabIndex="3"
                inputStyle={{ cursor: 'pointer', ...defaultInputStyle }}
                suffixIcon={
                  <CalendarIcon
                    height={20}
                    width={20}
                    style={{ position: 'absolute', top: '10px', right: '10px' }}
                  />
                }
                error={startDateError}
              />
              {isStartDateOpen && (
                <>
                  <Col size={{ xs: '12', sm: '4', md: '3' }}></Col>
                  <Col pos="relative">
                    <Calendar
                      value={new Date(contract?.startDate)}
                      className="calendar"
                      locale="ru-RU"
                      next2Label={null}
                      prev2Label={null}
                      onChange={(e) => onInputStartDateChange(e)}
                    />
                  </Col>
                </>
              )}
            </Row>
          </Col>
          <Col size={{ xs: '12', md: '5', lg: '5' }}>
            <Row p={{ r: { xs: '0.5rem', lg: '2rem' } }}>
              <FormField
                colSize="12"
                disabled
                label={<FormattedMessage id="endContractDate" />}
                inputName="calendar"
                inputType="text"
                inputValue={dateNowFormatMessage(contract?.endDate)}
                onClick={() => setIsEndDateOpen((prev) => !prev)}
                required={true}
                tabIndex="3"
                inputStyle={{ cursor: 'pointer', ...defaultInputStyle }}
                suffixIcon={
                  <CalendarIcon
                    height={20}
                    width={20}
                    style={{ position: 'absolute', top: '10px', right: '10px' }}
                  />
                }
                error={endDateError}
              />
              {isEndDateOpen && (
                <>
                  <Col size={{ xs: '12', sm: '4', md: '3' }}></Col>
                  <Col pos="relative">
                    <Calendar
                      value={new Date(contract?.endDate)}
                      name="endDate"
                      className="calendar"
                      locale="ru-RU"
                      next2Label={null}
                      prev2Label={null}
                      onChange={(e) => onInputEndDateChange(e)}
                    />
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
      )}
      <Div pos="relative">
        {loading && (
          <Div
            d="flex"
            justify="center"
            align="center"
            w="100%"
            h="100%"
            bg="rgba(255,255,255,0.7)"
            pos="absolute"
            zIndex="99"
          >
            <Icon name="Loading" size="40px" />
          </Div>
        )}
        <Div
          m={{ y: '1.5rem', x: '1rem' }}
          d="flex"
          w="auto"
          flexWrap="nowrap"
          overflow="auto"
          style={{ whiteSpace: 'nowrap' }}
        >
          {contract?.tariffs?.map((el, index) => {
            return (
              <Div key={index}>
                <SubMenu
                  isActive={index === submenuItem}
                  menuItem={el.name}
                  error={
                    el?.fieldNameError ||
                    el?.eventTypeError ||
                    el?.fieldValueError
                  }
                  onClick={() => onSubmenuItemClick(index)}
                  maxWidth="100%"
                  edit={index === submenuItem}
                  isDelete={index === submenuItem}
                  onDeleteClick={deleteTariff}
                  onEditClick={() => {
                    setStartingTariff({
                      ...startingTariff,
                      name: el.name,
                      popup: true,
                      update: true,
                    });
                  }}
                />
              </Div>
            );
          })}
          <Div>
            <SubMenu
              isActive={false}
              menuItem={`${formatMessage('add')} +`}
              onClick={() =>
                setStartingTariff({
                  ...startingTariff,
                  popup: true,
                })
              }
              maxWidth="100%"
            />
          </Div>
        </Div>
        {contract?.tariffs?.length ? (
          <CreateContractForm
            data={contract?.tariffs[submenuItem]?.fields}
            notes={createContractNotes.feeUnloadingLoadingOperations}
            btnIcon="Checked"
            index={submenuItem}
            btnText="createContractBtn"
            onClick={onSubmit}
            loading={false}
            isInvalid={false}
          />
        ) : null}
      </Div>
      {startingTariff.popup && (
        <DangerPopup
          minWidth="500px"
          title={<FormattedMessage id="writeNameForTariff" />}
          submitText={<FormattedMessage id="add" />}
          cancelText={<FormattedMessage id="cancel" />}
          onClose={onCloseAddTariffPopup}
          onSubmit={onSubmitAddTariffPopup}
        >
          <Div w="100%">
            <form onSubmit={handleSubmit}>
              <FormField
                inputName="tariffName"
                inputType="text"
                inputValue={startingTariff.name}
                label={formatMessage('nameOfTariff')}
                onChange={changeTariffName}
              />
              <Text textColor="red" m={{ l: '10px', t: '-10px' }}>
                {startingTariff?.error?.code && (
                  <FormattedMessage id={startingTariff?.error?.code} />
                )}
              </Text>
            </form>
          </Div>
        </DangerPopup>
      )}
      <ErrorNotification
        isOpen={validateError}
        onClose={() => setValidateError(false)}
        title={<FormattedMessage id="fillRequiredFields" />}
      />
    </Div>
  );
}
