// External dependencies
import { Icon, Notification } from 'atomize';
import React from 'react';

interface NotificationProps {
  title: any;
  onClose: () => void;
  isOpen: boolean;
}

export default function ErrorNotification({
  isOpen,
  title,
  onClose,
}: NotificationProps) {
  return (
    <Notification
      bg="danger_color"
      isOpen={isOpen}
      onClose={onClose}
      prefix={
        <Icon name="CloseSolid" color="white" size="18px" m={{ r: '0.5rem' }} />
      }
    >
      {title}
    </Notification>
  );
}
